import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Container,
  FormText,
  
} from "react-bootstrap";
import * as apiUtility from "../../config/APIUtils";
import SuccessPopUp from "../Utility/SuccessPopUp";
import ErrorPopUp from "../Utility/ErrorPopUp";
import SpinnerBox from "../Utility/SpinnerBox";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  Tooltip,
} from "@mui/material";
import { RxInfoCircled } from "react-icons/rx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import UserConsentDialog from "../Utility/UserConsentDialog";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, CardContent, IconButton, Typography,CardOverflow,Alert, } from "@mui/joy";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL_FRONTEND } from '../../config/Url-config';

import { AlbertaCities, BritishColumbiaCities, ManitobaCities, NewBrunswickCities, NewFoundLandCities, NorthWestCities, NovaScotiaCities, NunavatCities, OntarioCities, PEICities, QuebecCities, SaskatchewanCities, YukonCities } from "../Utility/Constants";
const intialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  userName: "",
  cnum: "",
  password: "",
  confirmPassword: "",
  dob: "",
  gender: "",
  email: "",
  number: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  city: "",
  state: "",
  postalCode: "",
  // careerGoal: '',
  // comCode: '',
  enrollCheck: false,
  consent: false,
};

const passVerificationError = {
  isLengthy: false,
  hasUpper: false,
  hasLower: false,
  hasNumber: false,
  hasSpecChar: false,
  confirmPassword: false,
};

var errors = {
  firstName: false,
  userName: false,
  email: false,
  dob: false,
  gender: false,
  number: false,
  cnum: false,
  country: false,
  city: false,
  state: false,
  postal: false,
  address: false,
};

var errorText = {
  firstName: "",
  userName: "",
  email: "",
  dob: "",
  gender: "",
  number: "",
  cnum: "",
  country: "",
  city: "",
  state: "",
  postal: "",
  address: "",
};

const data = {
  countries: [
    {
      name: "USA",
      states: [
        { name: "Massachusetts", cities: ["Downers Grove"] },
        { name: "New York", cities: ["asdk"] },
      ],
    },
    {
      name: "Canada",
      states: [
        {
          name: "Ontario",
          cities: OntarioCities
        },
        {
          name: "British Columbia",
          cities: BritishColumbiaCities
        },
        {
          name: "Manitoba",
          cities: ManitobaCities
        },
        {
          name: "New Brunswick",
          cities: NewBrunswickCities
        },
        {
          name: "Newfoundland and Labrador",
          cities: NewFoundLandCities
        },
        {
          name: "Northwest Territories",
          cities: NorthWestCities
        },
        {
          name: "Nova Scotia",
          cities: NovaScotiaCities
        },
        {
          name: "Alberta",
          cities: AlbertaCities
        },
        {
          name: "Nunavut",
          cities: NunavatCities
        },
        {
          name: "Prince Edward Island",
          cities: PEICities
        },
        {
          name: "Quebec",
          cities: QuebecCities
        },
        {
          name: "Saskatchewan",
          cities: SaskatchewanCities
        },
        { name: "Yukon Territory", cities: YukonCities },
      ],
    },
  ],
};

function UserRegistration() {
  const BASE_URL_USER_SAVE = "user/save/";
  const BASE_URL_TRAINING_PROVIDER = "user/getTrainingProviderList/";
  const [newUser, setNewUser] = useState(intialState);
  const [passwordError, setPasswordError] = useState(passVerificationError);
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [selectedState, setSelectedState] = React.useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showUserMsg, setShowUserMsg] = useState(false);
  const [userMsg, setUserMsg] = useState("");
  const [validationError, setValidationError] = useState(errors);
  const [errorDesc, setErrorDesc] = useState(errorText);
  const [validated, setValidated] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [arrayConsent, setArrayConsent] = useState("");

  const [checked, setChecked] = useState(true);
  const [enrollCheck, setTrainingChecked] = useState(false);
  const [consentDialog, setConsentDialog] = useState(false);
  const [trainingProviders, setTrainingProviders] = useState(
    sessionStorage.getItem("training_providers") != undefined
      ? JSON.parse(sessionStorage.getItem("training_providers"))
      : []
  );
  const [showNewCnumError, setShowNewCnumError] = useState("");
  const [showCnumError, setShowCnumError] = useState("");
  const [showValidateCnumError, setValidateCnumError] = useState(false);
  const [showValidRegex, setShowValidRegex] = useState(false);
  const [consentArray, setConsentArray] = useState([]);
  const [trainingErrorText, setTrainingErrorText] = useState({});

  const availableState = data.countries.find((c) => c.name === selectedCountry);
  // const availableCities = availableState?.states?.find(
  //     (s) => s.name === selectedState
  // );
  //const params = useParams().id;
  const [showForm, setShowForm] = useState(true);
  const [studentData, setStudentData] = useState({});
  const [newUserTrainingData, setNewUserTrainingData] = useState([]);

  useEffect(async () => {
    if (!sessionStorage.getItem("training_providers")) {
      var apiRequest = apiUtility.apiInputRequest;
      apiRequest.endPoint = BASE_URL_TRAINING_PROVIDER;
      const res = await apiUtility.fetchAPIDataGET(apiRequest);
      if (res.data.data.length > 0) {
        var tempArray = res.data.data.map(
          ({
            tp_id,
            student_number_regex,
            provider_name,
            student_number_check,
            student_number_regex_detail,
            student_number_length
          }) => ({
            tp_id,
            student_number_regex,
            provider_name,
            student_number_check,
            student_number_regex_detail,
            student_number_length
          })
        );
        sessionStorage.setItem("training_providers", JSON.stringify(tempArray));
        setTrainingProviders(tempArray);
      }
    }
    if (sessionStorage.getItem("tempRegTrainingData")) {
      sessionStorage.removeItem("tempRegTrainingData");
    }
  }, [newUser]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    const length = value.length <= 0;
    if (name === "country") {
      setSelectedCountry(value);
      setSelectedState(-1);
      newUser.state = "";
    }
    if (name === "enrollCheck") {
      setChecked(e.target.checked);
      setConsentDialog(e.target.checked);
    }
    if (name === "state") {
      setSelectedState(value);
    }

    var firstName = errors["firstName"];
    var userName = errors["userName"];
    var email = errors["email"];
    var dob = errors["dob"];
    var number = errors["number"];
    var firstNameDesc = errorText["firstName"];
    var userNameDesc = errorText["userName"];
    var emailDesc = errorText["email"];
    var dobDesc = errorText["dob"];
    var numberDesc = errorText["number"];
    var cnum = errors["cnum"];
    var cnumDesc = errorText["cnum"];
    var country = errors["country"];
    var countryDesc = errorText["country"];
    var state = errors["state"];
    var stateDesc = errorText["state"];
    var city = errors["city"];
    var cityDesc = errorText["city"];
    var postal = errors["postal"];
    var postalDesc = errorText["postal"];
    var address = errors["address"];
    var addressDesc = errorText["address"];
    var gender = errors["gender"];
    var genderDesc = errorText["gender"];

    if (name === "firstName") {
      errorText["firstName"] = "";
      firstNameDesc = "";
      firstName = false;
      if (!value.match(/^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<, -]*$/i)) {
        firstNameDesc = "Only letters are allowed";
        errorText["firstName"] = firstNameDesc;
        firstName = true;
      }
      errors["firstName"] = firstName;
    }

    if (name === "userName") {
      userNameDesc = "";
      userName = false;
      errorText["userName"] = "";
      if (length) {
        userName = true;
        errorText["userName"] = "Username is mandatory";
      } else {
        if (!value.match(/^[a-zA-Z0-9]+$/)) {
          userNameDesc = "Only alpha numeric allowed";
          errorText["userName"] = userNameDesc;
          userName = true;
        }
      }
      errors["userName"] = userName;
    }

    if (name === "email") {
      emailDesc = "";
      email = false;
      errorText["email"] = "";
      if (length) {
        email = true;
        emailDesc = "Email cannot be left empty";
        errorText["email"] = "Email cannot be left empty";
      } else {
        if (!validateEmail(value)) {
          email = true;
          emailDesc = "Email is not valid";
          errorText["email"] = "Email is not valid";
        }
      }
      errors["email"] = email;
    }

    if (name === "dob") {
      dobDesc = "";
      dob = false;
      errorText["dob"] = "";
      if (length) {
        dob = true;
        dobDesc = "Date of Birth is mandatory";
        errorText["dob"] = "Date of Birth is mandatory";
      } else {
        if (calAge(value) < 10) {
          dob = true;
          dobDesc = "Should be 10 years old";
          errorText["dob"] = "Should be 10 years old";
        }else if(isInvalidYears(value)){
          dob = true;
          dobDesc = "Invalid years!";
          errorText["dob"] = "Invalid years!";
        }

      }
      errors["dob"] = dob;
    }

    if (name === "gender") {
      errorText["gender"] = "";
      genderDesc = "";
      gender = false;
      if (value === -1 || value === "-1") {
        genderDesc = "Gender is mandatory";
        errorText["gender"] = "Gender is mandatory";
        gender = true;
      }
      errors["gender"] = gender;
    }

    if (name === "country") {
      errorText["country"] = "";
      countryDesc = "";
      country = false;
      if (value === -1 || value === "-1") {
        countryDesc = "Country is mandatory";
        errorText["country"] = "Country is mandatory";
        country = true;
      }
      errors["country"] = country;
    }
    if (name === "state") {
      errorText["state"] = "";
      stateDesc = "";
      state = false;
      if (value === -1 || value === "-1") {
        stateDesc = "State is mandatory";
        errorText["state"] = "State is mandatory";
        state = true;
      }
      errors["state"] = state;
    }
    if (name === "city") {
      errorText["city"] = "";
      cityDesc = "";
      city = false;
      if (length) {
        cityDesc = "City is mandatory";
        errorText["city"] = "City is mandatory";
        city = true;
      }
      errors["city"] = city;
    }

    if (name === "postalCode") {
      errorText["postal"] = "";
      postalDesc = "";
      postal = false;
      if (length) {
        postalDesc = "Postal Code is mandatory";
        errorText["postal"] = "Postal Code is mandatory";
        postal = true;
      }
      // if(value.length!=7){
      //   postalDesc = "Postal Code length must be 7";
      //   errorText["postal"] = "Postal Code length must be 7";
      //   postal = true;
      // }
      const postalCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

        if (postalCodeRegex.test(value)) {
          console.log("Postal code is valid.");
        } else {
          postalDesc = "Postal Code should be of format LNL NLN";
          errorText["postal"] = "Postal Code should be of format LNL NLN";
          postal = true;        }
          errors["postal"] = postal;
          }

    if (name === "addressLine1") {
      errorText["address"] = "";
      addressDesc = "";
      address = false;
      if (length) {
        addressDesc = "Address Line 1 is mandatory";
        errorText["address"] = "Address Line 1 is mandatory";
        address = true;
      }
      errors["address"] = address;
    }

    // if (name === "cnum") {
    //   cnumDesc = "";
    //   cnum = false;
    //   errorText["cnum"] = "";
    //   if (!length) {
    //     if (!value.match(/^[a-zA-Z0-9]+$/)) {
    //       cnumDesc = "Only alpha numeric allowed";
    //       errorText["cnum"] = cnumDesc;
    //       cnum = true;
    //     } else {
    //       let cNumObj = { cnumFlag: cnum, cnumDesc: cnumDesc };
    //       validateLambtonId(value, cNumObj);
    //       cnum = cNumObj.cnumFlag;
    //       cnumDesc = cNumObj.cnumDesc;
    //     }
    //   }
    //   errors["cnum"] = cnum;
    // }

    setValidationError({
      ...validationError,
      firstName,
      userName,
      email,
      dob,
      number,
      cnum,
      country,
      state,
      city,
      postal,
      address,
      gender,
    });
    setErrorDesc({
      ...errorDesc,
      firstNameDesc,
      userNameDesc,
      emailDesc,
      dobDesc,
      numberDesc,
      cnumDesc,
      countryDesc,
      stateDesc,
      cityDesc,
      postalDesc,
      addressDesc,
      genderDesc,
    });

    if (name === "confirmPassword") {
      setPasswordError({
        ...passwordError,
        confirmPassword: newUser.password === value,
      });
    }
    if (name === "password") {
      const isLengthy = value.length >= 8;
      const hasUpper = /[A-Z]/.test(value);
      const hasLower = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecChar = /[@,#,$,%,&,*,!]/.test(value);

      setPasswordError({
        ...passwordError,
        isLengthy,
        hasLower,
        hasUpper,
        hasNumber,
        hasSpecChar,
      });
    }
  };

  const calAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isInvalidYears = (dateString) => {
    const birthDate = new Date(dateString);
    if(birthDate.getFullYear() < 1900){
      return true;
    }
    return false;
  };

  
  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const validateDate = (value) => {
    var number = errors["number"];
    var numberDesc = errorText["number"];
    errorText["number"] = "";
    numberDesc = "";
    validationError["number"] = false;
    number = false;
    if (value === undefined || value === "") {
      numberDesc = "Contact number is mandatory";
      errorText["number"] = "Contact number is mandatory";
      number = true;
    } else {
    //  console.log(isPossiblePhoneNumber(value));
      if (!isPossiblePhoneNumber(value)) {
        numberDesc = "Invalid Contact Number";
        errorText["number"] = "Invalid Contact Number";
        number = true;
      }
    }
    newUser.number = value;
    errors["number"] = number;
    validationError.number = number;
    errorDesc.number = numberDesc;
  };

  const validateMandatoryOnSubmit = (newUser) => {
    var error = false;
    if (newUser.email === "" && newUser.email.length <= 0) {
      errors["email"] = true;
      validationError["email"] = true;
      errorText["email"] = "Email cannot be left empty";
      errorDesc["emailDesc"] = "Email cannot be left empty";
    }
    if (newUser.dob === "" && newUser.dob.length <= 0) {
      errors["dob"] = true;
      validationError["dob"] = true;
      errorText["dob"] = "Date of Birth is mandatory";
      errorDesc["dobDesc"] = "Date of Birth is mandatory";
    }

    if (
      newUser.gender === "" ||
      newUser.gender === "-1" ||
      newUser.gender === -1
    ) {
      errors["gender"] = true;
      validationError["gender"] = true;
      errorText["gender"] = "Gender is mandatory";
      errorDesc["genderDesc"] = "Gender is mandatory";
    }

    if (newUser.number === "" && newUser.number.length <= 0) {
      errors["number"] = true;
      validationError["number"] = true;
      errorText["number"] = "Contact Number is mandatory";
      errorDesc["numberDesc"] = "Contact Number is mandatory";
    }

    if (
      newUser.country === "" ||
      newUser.country === "-1" ||
      newUser.country === -1
    ) {
      errors["country"] = true;
      validationError["country"] = true;
      errorText["country"] = "Country is mandatory";
      errorDesc["countryDesc"] = "Country is mandatory";
    }
    if (
      newUser.state === "" ||
      newUser.state === "-1" ||
      newUser.state === -1
    ) {
      errors["state"] = true;
      validationError["state"] = true;
      errorText["state"] = "State is mandatory";
      errorDesc["stateDesc"] = "State is mandatory";
    }
    if (newUser.city === "" && newUser.city.length <= 0) {
      errors["city"] = true;
      validationError["city"] = true;
      errorText["city"] = "City is mandatory";
      errorDesc["cityDesc"] = "City is mandatory";
    }
    if (newUser.postalCode === "" || newUser.postalCode.length !=7 || newUser.postalCode.charAt(3)!=" ") {
      errors["postal"] = true;
      validationError["postal"] = true;
      errorText["postal"] = "Invalid format for postal code";
      errorDesc["postalDesc"] = "Invalid format for postal code";
    }
    if (newUser.addressLine1 === "" && newUser.addressLine1.length <= 0) {
      errors["address"] = true;
      validationError["address"] = true;
      errorText["address"] = "Address Line 1 is mandatory";
      errorDesc["addressDesc"] = "Address Line 1 is mandatory";
    }

    if (newUser.userName === "" && newUser.userName.length <= 0) {
      errors["userName"] = true;
      validationError["userName"] = true;
      errorText["userName"] = "Username is mandatory";
      errorDesc["userNameDesc"] = "Username is mandatory";
    }


    if (
      newUser.firstName === "" &&
      newUser.firstName.length <= 0 &&
      newUser.lastName === "" &&
      newUser.lastName.length <= 0
    ) {
      // setErrorMsg("User's first or last name is mandatory. Both fields cannot be left empty.");
      // setShowErrorMsg(true);
      // return;
      errors["firstName"] = true;
      validationError["firstName"] = true;
      errorText["firstName"] =
        "User's first or last name is mandatory. Both fields cannot be left empty.";
      errorDesc["firstNameDesc"] =
        "User's first or last name is mandatory. Both fields cannot be left empty.";
    }

    if(studentData.providerName!="" && studentData.providerName!=undefined && studentData.consent==false){
      setErrorMsg(
        "Please fill in the training provider details and consent before submitting."
      );
      setShowErrorMsg(true);
      return true;
    }
    var tempRegTrainingData;
    if (sessionStorage.getItem("tempRegTrainingData")) {
      setNewUserTrainingData(
        JSON.parse(sessionStorage.getItem("tempRegTrainingData"))
      );
      tempRegTrainingData = JSON.parse(
        sessionStorage.getItem("tempRegTrainingData")
      );
    } else {
      tempRegTrainingData = newUserTrainingData;
    }

    if (tempRegTrainingData.length != 0) {
      var match = false;
      for (var i = 0; i < tempRegTrainingData.length; i++) {
        if (tempRegTrainingData[i].consent == false) {
          setErrorMsg(
            "Please Consent to agreement for all training providers to continue."
          );
          setShowErrorMsg(true);
          return true;
        }
        if (tempRegTrainingData[i].enrollCheck == false) {
          var provData = JSON.parse(sessionStorage.getItem("training_providers"));
          var getRegex = provData.find(
            (item) => item.tp_id === parseInt(tempRegTrainingData[i].tp_id)
          );
          const regex = new RegExp(getRegex.student_number_regex);
          match = regex.test(tempRegTrainingData[i].num);

          if (tempRegTrainingData[i].num.length == 0) {
            match = false;
            errorText["cnum"] = "*student no. string cannot be empty"
            setShowCnumError("*student no. string cannot be empty");
            setErrorMsg(
              "Incorrect or missing details. Please update highlighted fields value to proceed further."
            );
            setShowErrorMsg(true);
            return true;

          }
          if (!match) {
            errorText["cnum"] = ("** Entered student number do not match with pattern provided from " + getRegex.provider_name);
            setErrorMsg(
              "Incorrect or missing details. Entered student number do not match with pattern provided from " + getRegex.provider_name
            );
            setShowErrorMsg(true);
            return true;
          }
        }

      }
      if (match) {
        errorText["cnum"] = "";
      }
    }



    if (Object.values(validationError).includes(true)) {
      setErrorMsg(
        "Incorrect or missing details. Please update highlighted fields value to proceed further."
      );
      setShowErrorMsg(true);
      error = true;
    }

    return error;
  };

  const validateConsentOnEnrollment = (newUser) => {
    var error = false;
    //console.log("Checked : ", checked);
    if (!checked) return error;

    if (checked && newUser.cnum !== "" && newUser.cnum.length > 0) {
      setErrorMsg("Cannot apply for a new student ID with exisitng Lambton ID");
      setShowErrorMsg(true);
      error = true;
    }
    // else if (
    //   newUser.consent === false &&
    //   newUser.cnum !== "" &&
    //   newUser.cnum.length > 0
    // ) {
    //   setConsentDialog(true);
    //   error = true;
    // } 
    else if (checked && newUser.consent === false) {
      // setErrorMsg("Please review and confirm 'use of personal information' statement");
      // setShowErrorMsg(true);
      setConsentDialog(true);
      error = true;
    }
    return error;
  };

  const validateLambtonId = (value, cNumObj) => {
    const regex = /^[abctz]/i;
    var cnumDesc = "";
    if (regex.test(value)) {
      if (
        (value.startsWith("c", 0) || value.startsWith("C", 0)) &&
        value.length !== 8
      ) {
        cnumDesc =
          "Lambton ID starting with 'c0' should contain no more or less than 6 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
      if (
        (value.startsWith("Z", 0) || value.startsWith("z", 0)) &&
        value.length !== 4 &&
        value.length !== 5
      ) {
        cnumDesc =
          "Lambton ID starting with 'z' should contain no more than 4 or less than 3 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
      if (
        (value.startsWith("a", 0) ||
          value.startsWith("b", 0) ||
          value.startsWith("t", 0) ||
          value.startsWith("A", 0) ||
          value.startsWith("B", 0) ||
          value.startsWith("T", 0)) &&
        value.length !== 4
      ) {
        cnumDesc =
          "Lambton ID starting with 'a' OR 'b' OR 't' should contain no more or less than 4 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
    } else {
      cnumDesc =
        "Lambton ID should starts with 'a' OR 'b' OR 'c' OR 't' OR 'z'";
      errorText["cnum"] = cnumDesc;
      cNumObj.cnumFlag = true;
      cNumObj.cnumDesc = cnumDesc;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateMandatoryOnSubmit(newUser)) {
        //Form error exist
        return;
      }
      // else if (validateConsentOnEnrollment(newUser)) {
      //   return;
      // }
      else {
        newUser.enrollCheck =
          newUser.enrollCheck === undefined ? false : newUser.enrollCheck;
        newUser.consent =
          newUser.consent === true;
        newUser.firstName = newUser.firstName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.lastName = newUser.lastName.trim();
        newUser.middleName = newUser.middleName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.userName = newUser.userName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.enrollCheck = checked;
        newUser.student_numbers = newUserTrainingData;
        newUser.email = newUser.email.toLowerCase();
        if (newUser.firstName === "" || newUser.firstName.length <= 0) {
          newUser.firstName = "-";
        }
        if (newUser.lastName === "" || newUser.lastName.length <= 0) {
          newUser.lastName = "-";
        }
        if (newUser.middleName === "" || newUser.middleName.length <= 0) {
          newUser.middleName = "-";
        }
      }



      var apiRequest = apiUtility.apiInputRequest;
      apiRequest.endPoint = BASE_URL_USER_SAVE;
      apiRequest.data = newUser;
      const res = await apiUtility.sendAsyncAPIDataPOST(apiRequest);
      const resJson = res.data;
      //console.log("Response : ", resJson)
      setShowSpinner(false);
      setShowCnumError("");
      if (
        resJson !== undefined &&
        resJson !== "Username or Email Already in use!"
      ) {
        setValidated(true);
        setShowUserMsg(true);
        setUserMsg(
          "User successfully registered! \n Please check your email and verify before login"
        );
        return window.location.href = BASE_URL_FRONTEND
      } else if (resJson === "Username or Email Already in use!") {
        setErrorMsg("Username or Email already exists!");
        setShowErrorMsg(true);
      } else {
        setErrorMsg("Something went wrong! Please try again.");
        setShowErrorMsg(true);
      }


      // }
    } catch (error) {
      setShowSpinner(false);
      setErrorMsg("Something went wrong! Please try again.");
      setShowErrorMsg(true);
      console.log(error);
    }
  };

  const handleUpdateCallback = (result) => {
    setConsentDialog(false);
    //console.log(arrayConsent)
    if (String(arrayConsent) != "") {
      var tempTrainingData = [...newUserTrainingData];
      tempTrainingData[arrayConsent].consent = result;
      setNewUserTrainingData(tempTrainingData);
      sessionStorage.setItem(
        "tempRegTrainingData",
        JSON.stringify(tempTrainingData)
      );
    }
    else {
      // Create a copy of the newUserTrainingData array
      var tempConsentArray = [...newUserTrainingData]; // Spread operator to avoid mutation
      // Get the last object and modify it immutably
      var tempConsentObj = { ...tempConsentArray[tempConsentArray.length - 1] }; // Shallow copy of the object
      tempConsentObj.consent = result; // Update the consent value

      // Replace the last object with the updated one
      tempConsentArray[tempConsentArray.length - 1] = tempConsentObj;

      // Log and update state immutably
      setNewUserTrainingData(tempConsentArray); // Update the state with the new array




      setNewUserTrainingData(tempConsentArray); // Update the state with the new array


      sessionStorage.setItem(
        "tempRegTrainingData",
        JSON.stringify(tempConsentArray)
      );
      setShowForm(false);
    }

    setArrayConsent("")

    // newUser.consent = result; // Assuming this is fine and necessary


  };

  // New functions for Multiple training Provider logic
  function handleOnChangeTP(ev, txt) {
    //console.log(ev.target.value, txt);
    var tempTrainingData = {};
    tempTrainingData = { ...studentData };
    if (studentData.enrollCheck == undefined) {
      if ((trainingProviders.find((item) => String(item.tp_id) === studentData.providerName)?.student_number_check == false)) {
        tempTrainingData.enrollCheck = true;
      }
      else {
        tempTrainingData.enrollCheck = false;
      }
    }
    if (txt == "providerName") {
      tempTrainingData.providerName = ev.target.value;
      tempTrainingData.enrollCheck = true;

      // tempTrainingData.enrollCheck = !(trainingProviders.find((item) => String(item.tp_id) === studentData.providerName)?.student_number_check)
      tempTrainingData.studentNo = "";
      tempTrainingData.consent = false;
      errorText["cnum"] = ""
      setShowCnumError("");
      setStudentData(tempTrainingData);

    }
    else if (txt == "enrollCheck") {
      // setTrainingChecked(ev.target.checked);
      if (ev.target.checked == true) {
        tempTrainingData.studentNo = "";
      }
      tempTrainingData.enrollCheck = ev.target.checked;
      setStudentData(tempTrainingData);
      errorText["cnum"] = "";
      //console.log(studentData)

    }
    else if (txt == "consentCheck") {
      tempTrainingData.consent = ev.target.checked;
      setStudentData(tempTrainingData);
      setConsentDialog(true);
      //console.log(studentData)
      if (studentData.providerName &&
        studentData.providerName != "-1" &&
        !studentData.studentNo) {
        if (
          (trainingProviders.find(
            (item) => String(item.tp_id) === String(studentData.providerName)
          )?.student_number_check == false)
        ) {
          var tempStudentData = { ...studentData };
          tempStudentData.studentNo = "";
          tempStudentData.consent = false;
          var tempUserTrainingData;
          if (sessionStorage.getItem("tempRegTrainingData")) {
            tempUserTrainingData = JSON.parse(
              sessionStorage.getItem("tempRegTrainingData")
            );
            setShowCnumError("");

            tempUserTrainingData.push({
              tp_id: parseInt(studentData.providerName),
              num: "",
              consent: false,
              enrollCheck: studentData.enrollCheck
            });
            setNewUserTrainingData(tempUserTrainingData);
            sessionStorage.setItem(
              "tempRegTrainingData",
              JSON.stringify(tempUserTrainingData)
            );
            setConsentDialog(true);
            // setTrainingChecked(false)
            setShowForm(false);
            setStudentData({});
            // setValidateCnumError(false);
            // setShowValidRegex(true);
          }
          else {
            var tempUserTrainingData = [];
            setShowCnumError("");

            tempUserTrainingData.push({
              tp_id: parseInt(studentData.providerName),
              num: "",
              consent: false,
              enrollCheck: studentData.enrollCheck
            });
            setNewUserTrainingData(tempUserTrainingData);
            sessionStorage.setItem(
              "tempRegTrainingData",
              JSON.stringify(tempUserTrainingData)
            );
            // setConsentArray([...consentArray,{"tp_id":studentData.providerName,"consent":false}]);

            setConsentDialog(true);
            setShowForm(false);
            setStudentData({});
            setValidateCnumError(false);
            setShowValidRegex(true);
          }
        }
        else if ((trainingProviders.find(
          (item) => String(item.tp_id) === String(studentData.providerName)
        )?.student_number_check == true)) {
          var tempStudentData = { ...studentData };
          tempStudentData.studentNo = "";
          tempStudentData.consent = false;
          var tempUserTrainingData;
          if (sessionStorage.getItem("tempRegTrainingData")) {
            tempUserTrainingData = JSON.parse(
              sessionStorage.getItem("tempRegTrainingData")
            );
            setShowCnumError("");

            tempUserTrainingData.push({
              tp_id: parseInt(studentData.providerName),
              num: "",
              consent: false,
              enrollCheck: studentData.enrollCheck
            });
            setNewUserTrainingData(tempUserTrainingData);
            sessionStorage.setItem(
              "tempRegTrainingData",
              JSON.stringify(tempUserTrainingData)
            );
            setConsentDialog(true);
            // setTrainingChecked(false)
            setShowForm(false);
            setStudentData({});
          }
          else {

            var tempUserTrainingData = [];
            setShowCnumError("");

            tempUserTrainingData.push({
              tp_id: parseInt(studentData.providerName),
              num: studentData.studentNo ? studentData.studentNo : "",
              consent: false,
              enrollCheck: studentData.enrollCheck
            });
            setNewUserTrainingData(tempUserTrainingData);
            sessionStorage.setItem(
              "tempRegTrainingData",
              JSON.stringify(tempUserTrainingData)
            );
            // setConsentArray([...consentArray,{"tp_id":studentData.providerName,"consent":false}]);

            setConsentDialog(true);
            setShowForm(false);
            setStudentData({});
            // setValidateCnumError(false);
            // setShowValidRegex(true);
          }
        } else {
          alert("Please fill all fields!");
        }
      }
      else if (
        studentData.studentNo &&
        studentData.providerName &&
        studentData.providerName != "-1"
      ) {
        var provData = JSON.parse(sessionStorage.getItem("training_providers"));
        var getRegex = provData.find(
          (item) => item.tp_id === parseInt(studentData.providerName)
        );
        //console.log(getRegex);
        if (getRegex.student_number_check == true) {
          const regex = new RegExp(getRegex.student_number_regex);
          const match = regex.test(studentData.studentNo);
          if (match) {
            var tempUserTrainingData = [...newUserTrainingData];
            if (sessionStorage.getItem("tempRegTrainingData")) {
              tempUserTrainingData = JSON.parse(
                sessionStorage.getItem("tempRegTrainingData")
              );
            }

            // setShowCnumError("");

            tempUserTrainingData.push({
              tp_id: parseInt(studentData.providerName),
              num: studentData.studentNo,
              consent: false,
              enrollCheck: studentData.enrollCheck
            });
            setNewUserTrainingData(tempUserTrainingData);
            sessionStorage.setItem(
              "tempRegTrainingData",
              JSON.stringify(tempUserTrainingData)
            );
            //setConsentArray([...consentArray,{"tp_id":studentData.providerName,"consent":false}]);

            setShowForm(false);
            setStudentData({});
            errorText["cnum"] = ""
            setShowCnumError("");
            setConsentDialog(true);
          } else {
            errorText[`cnum`] = "*Student no. does not match with the format for " + getRegex.provider_name;
            // setShowCnumError(errors);
            // setShowNewCnumError("");

            //console.log(showNewCnumError)
            //setShowValidRegex(false);
            //setValidateCnumError(true);
          }
        }
        else {
          var tempUserTrainingData = [...newUserTrainingData];
          if (sessionStorage.getItem("tempRegTrainingData")) {
            tempUserTrainingData = JSON.parse(
              sessionStorage.getItem("tempRegTrainingData")
            );
          }

          // setShowCnumError("");

          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: "",
            consent: false,
            enrollCheck: true
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempRegTrainingData",
            JSON.stringify(tempUserTrainingData)
          );
        }

      }
      //setStudentData(tempTrainingData);
    }
    else if(txt == "remove" && newUserTrainingData.length!=0){
      setStudentData({});
      setShowForm(false);
    }
    else if(txt == "remove" && studentData.providerName && studentData.providerName!=undefined && newUserTrainingData.length==0){

      setStudentData({});
       setShowForm(true);
    }
    else {
      tempTrainingData.studentNo = ev.target.value;
      tempTrainingData.enrollCheck = false;

      //var errors = {};
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => item.tp_id === parseInt(studentData.providerName)
      );
      //console.log(getRegex);
      const regex = new RegExp(getRegex.student_number_regex);
      const match = regex.test(tempTrainingData.studentNo);
      if(getRegex.student_number_length && parseInt(ev.target.value.length)!=parseInt(getRegex.student_number_length)){
        errorText["cnum"] = "*Student number length for " + getRegex.provider_name + " must be "+getRegex.student_number_length+" characters long";
        // setShowNewCnumError("*Student number length for " + getRegex.provider_name + " must be "+getRegex.student_number_length+" characters long");
        //errors[`cnum-${index}`] = "*Student no. " + ev.target.value + " does not match with the format for " + getRegex.provider_name;
       // setTrainingErrorText(errors);
      }
      else{
        if (!match) {
          // setShowNewCnumError("Invalid student number format");
          //  errors["cnum"] = true;
  
          errorText["cnum"] = "*Student no. does not match with the format for " + getRegex.provider_name;
          // setShowCnumError(errors);
          // console.log(showCnumError)
  
        }
        else {
          errorText["cnum"] = ""
          setShowCnumError("");
        }
      }
     
      // tempTrainingData.studentNo = ev.target.value;
      setStudentData(tempTrainingData);
      //console.log(tempTrainingData);
    }
  }
  function handleOnChangeTPArray(ev, id, txt, index) {
    //console.log("newUserTrainingData1", newUserTrainingData,ev.target.value);
    //var errors = {}
    var tempTrainingData;
    tempTrainingData = [...newUserTrainingData];

    if (txt == "providerName") {
      //let tempRegexCheck = newUserTrainingData[id].num;
      if (newUserTrainingData.find((item) => String(item.tp_id) === String(ev.target.value))) {
        alert("This training Provider is already selected!");
      } else {
        if ((trainingProviders.find((item) => String(item.tp_id) === String(ev.target.value))?.student_number_check == true)) {
          // tempTrainingData[index].enrollCheck = !(trainingProviders.find((item) => String(item.tp_id) === String(ev.target.value))?.student_number_check);
          tempTrainingData[index].enrollCheck = true;

          tempTrainingData[index].tp_id = parseInt(ev.target.value);
          tempTrainingData[index].num = "";
          tempTrainingData[index].consent = false;
          errors[`cnum-${index}`] = "";
          setTrainingErrorText(errors);
          // Update state and sessionStorage
          
        }
        else {
          // tempTrainingData[index].enrollCheck = !(trainingProviders.find((item) => String(item.tp_id) === String(ev.target.value))?.student_number_check);
          tempTrainingData[index].enrollCheck = true;

          tempTrainingData[index].tp_id = parseInt(ev.target.value);
          tempTrainingData[index].num = "";
          tempTrainingData[index].consent = false;
          
          //console.log(tempTrainingData)
          errors[`cnum-${index}`] = "";
          setTrainingErrorText(errors);


        }


      }
    }
    else if (txt == "enrollCheck") {

      tempTrainingData[index].num = "";
      tempTrainingData[index].enrollCheck = ev.target.checked;
      tempTrainingData[index].consent = false;
      //console.log(tempTrainingData[index].enrollCheck)
      // console.log(updatedData,ev.target.checked)
      // setNewUserTrainingData(tempTrainingData);
      // sessionStorage.setItem(
      //   "tempRegTrainingData",
      //   JSON.stringify(tempTrainingData)
      // );
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));

      var getRegex = provData.find(
        (item) => parseInt(item.tp_id) === parseInt(newUserTrainingData[index].tp_id)
      );
      if (showNewCnumError) {
        if (
          showNewCnumError.includes(getRegex.provider_name) ||
          showNewCnumError.includes(ev.target.value)
        ) {
          setShowNewCnumError("");
          errors[`cnum-${index}`] = "";
          setTrainingErrorText(errors);
        }
      }
    }
    else if (txt == "consent") {
      //console.log(ev.target.checked)
      setArrayConsent(index);
      setConsentDialog(true);

    }
    else {
      tempTrainingData[index].enrollCheck = false;
      tempTrainingData[index].num = (ev.target.value);
      tempTrainingData[index].consent = false;
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => parseInt(item.tp_id) === parseInt(newUserTrainingData[index].tp_id)
      );
      const regex = new RegExp(getRegex.student_number_regex);
      const match = regex.test(ev.target.value);

      if(getRegex.student_number_length && parseInt(tempTrainingData[index].num.length)!=parseInt(getRegex.student_number_length)){

        setShowNewCnumError("*Student number length for " + getRegex.provider_name + " must be "+getRegex.student_number_length+" characters long");
        errors[`cnum-${index}`] = "*Student number length for " + getRegex.provider_name + " must be "+getRegex.student_number_length+" characters long";
        setTrainingErrorText(errors);
      }
      else{
    
      if (match) {
        // setNewUserTrainingData(tempTrainingData);
        // sessionStorage.setItem(
        //   "tempRegTrainingData",
        //   JSON.stringify(tempTrainingData)
        // );
        if (showNewCnumError) {
          if (
            showNewCnumError.includes(getRegex.provider_name) ||
            showNewCnumError.includes(ev.target.value)
          ) {
            setShowNewCnumError("");
            errors[`cnum-${index}`] = "";
            setTrainingErrorText(errors);
          }
        }
      } else {
        setShowNewCnumError(
          "*Regex does not match student no. string for " +
          getRegex.provider_name +
          " with student no. " +
          ev.target.value
        );
        errors[`cnum-${index}`] = "*Student no. " + ev.target.value + " does not match with the format for " + getRegex.provider_name;
        setTrainingErrorText(errors);
      }
          
    }

      // setNewUserTrainingData(tempTrainingData);
    }
    setNewUserTrainingData(tempTrainingData);
    sessionStorage.setItem("tempRegTrainingData", JSON.stringify(tempTrainingData));
    // }
  }
  const handleAddNewField = (e, user, txt) => {
    if (txt === "remove") {
      const updatedItems = newUserTrainingData.filter(
        (item) => parseInt(item.tp_id) !== parseInt(user.tp_id)
      );
      const updatedConsentArray = consentArray.filter(item => parseInt(item.tp_id) !== parseInt(user.tp_id));
      setConsentArray(updatedConsentArray);
      //console.log("updatedItems", user, updatedItems, updatedConsentArray);
      sessionStorage.setItem(
        "tempRegTrainingData",
        JSON.stringify(updatedItems)
      );
      setNewUserTrainingData(updatedItems);
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => item.tp_id === parseInt(user.tp_id)
      );
      if (showCnumError) {
        if (
          showCnumError.includes(getRegex.provider_name) &&
          showCnumError.includes(user.num)
        ) {
          setShowCnumError("");
        }
      }
      if (updatedItems.length < 1) {
        setShowForm(true);
      }
    } else {
      setShowForm(true);
    }
  };


  

  return (
    <div style={{ marginTop: "6pc", marginBottom: "2pc" }}>
      <Container>
        <Row>
          <h3 className="text-center" style={{ color: "#0f6fc5" }}>
            Please fill out details
          </h3>
          <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
            <Row>
              <Form.Group as={Col} controlId="formGridName1" hasValidation>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="input"
                  name="firstName"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["firstName"]}
                  value={newUser.firstName}
                  placeholder="Enter your given name"
                />
                {/* <span className='text-danger'>{errorText["firstName"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["firstName"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridName3" hasValidation>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="input"
                  name="middleName"
                  onChange={handleOnChange}
                  value={newUser.middleName}
                  placeholder="Enter your middle name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridName2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="input"
                  name="lastName"
                  onChange={handleOnChange}
                  value={newUser.lastName}
                  placeholder="Enter your last name"
                />
              </Form.Group>
            </Row>

            <br></br>
            <Row>
              <Form.Group as={Col} controlId="formUserName" hasValidation>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="input"
                  name="userName"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["userName"]}
                  value={newUser.userName}
                  required
                  placeholder="Enter your username"
                />
                {/* <span className='text-danger'>{errorText["userName"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["userName"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label> &nbsp; &nbsp; Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleOnChange}
                  value={newUser.password}
                  required
                  placeholder="Enter your password"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridConfirmPassword">
                <Form.Label> &nbsp; Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  onChange={handleOnChange}
                  value={newUser.confirmPassword}
                  required
                  placeholder="Confirm your password"
                />
                {!passwordError.confirmPassword && (
                  <div className="text-danger mb-3">
                    {" "}
                    <p> &nbsp;&nbsp;&nbsp;Password doesn't match! </p>
                  </div>
                )}
              </Form.Group>
            </Row>
            <FormText>
              <ul className="mb-4">
                <li
                  className={
                    passwordError.isLengthy ? "text-success" : "text-danger"
                  }
                >
                  Min 8 characters{" "}
                </li>
                <li
                  className={
                    passwordError.hasLower ? "text-success" : "text-danger"
                  }
                >
                  At least one lower case{" "}
                </li>
                <li
                  className={
                    passwordError.hasUpper ? "text-success" : "text-danger"
                  }
                >
                  At least one upper case{" "}
                </li>
                <li
                  className={
                    passwordError.hasNumber ? "text-success" : "text-danger"
                  }
                >
                  At least one number{" "}
                </li>
                <li
                  className={
                    passwordError.hasSpecChar ? "text-success" : "text-danger"
                  }
                >
                  At least one special character{" "}
                </li>
              </ul>
            </FormText>

            <br></br>
            <Divider textAlign="left" role="presentation">
              <Typography level="body-xs"  variant="caption" gutterBottom>
                Personal Details
              </Typography>
            </Divider>
            <br></br>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDOB">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["dob"]}
                  value={newUser.dob}
                  required
                  placeholder="MM/dd/yyyy"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["dob"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridGender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name="gender"
                  value={newUser.gender}
                  isInvalid={!!errorText["gender"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose Gender--</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="U">Unknown</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["gender"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br></br>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["email"]}
                  value={newUser.email}
                  required
                  placeholder="Enter email"
                />
                {/* <span className='text-danger'>{errorText["email"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["email"]}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPhoneNo">
                <Form.Label>Contact Number</Form.Label>
                {/* <Form.Control type="number" name="number" onChange={handleOnChange} value={newUser.number} placeholder="Enter number" />
                                <Form.Control.Feedback type="invalid">{errorText["number"]}</Form.Control.Feedback> */}
                <PhoneInput
                  placeholder="Enter contact number"
                  name="number"
                  defaultCountry="CA"
                  value={newUser.number}
                  required={true}
                  onChange={validateDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["number"]}
                </Form.Control.Feedback>
                {validationError.number ? (
                  <div
                    className="text-danger"
                    style={{
                      marginLeft: "2.5pc",
                      fontSize: "0.8rem",
                      marginTop: "2px",
                    }}
                  >
                    {" "}
                    <p> {errorText["number"]} </p>
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>

            <br></br>
            <Divider textAlign="left" role="presentation">
              <Typography level="body-xs"  variant="caption" gutterBottom>
                Address Details
              </Typography>
            </Divider>
            <br></br>
            <Row className="mb-3">
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="formGridAddressLine1"
              >
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="input"
                  name="addressLine1"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["address"]}
                  value={newUser.addressLine1}
                  placeholder="Street address"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["address"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="formGridAddressLine2"
              >
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="input"
                  name="addressLine2"
                  onChange={handleOnChange}
                  value={newUser.addressLine2}
                  placeholder="apt., suite, unit, building , floor, etc."
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={newUser.country}
                  isInvalid={!!errorText["country"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose Country--</option>
                  {data.countries.map((e, key) => {
                    return (
                      <option value={e.name} key={key}>
                        {e.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["country"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  name="state"
                  value={newUser.state}
                  isInvalid={!!errorText["state"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose State--</option>
                  {availableState?.states.map((e, key) => {
                    return (
                      <option value={e.name} key={key}>
                        {e.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["state"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                {/* <Form.Select name="city" value={newUser.city} onChange={handleOnChange} >
                                <option>--Choose City--</option>
                                {availableCities?.cities.map((e, key) => {
                                    return (
                                    <option value={e.name} key={key}>
                                        {e}
                                    </option>
                                    );
                                })}
                                </Form.Select> */}
                <Form.Control
                  type="input"
                  name="city"
                  isInvalid={!!errorText["city"]}
                  onChange={handleOnChange}
                  value={newUser.city}
                  placeholder="Enter your city"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["city"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="input"
                  value={newUser.postalCode}
                  isInvalid={!!errorText["postal"]}
                  onChange={handleOnChange}
                  name="postalCode"
                  placeholder="Postal code"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["postal"]}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
            <br></br>
           
              <Divider textAlign="left" role="presentation">
                <Typography level="body-xs" variant="caption" gutterBottom>
                  Training Provider Details
                </Typography>
              </Divider>
              

              {newUserTrainingData?.map((user, index) => {
                return (<>
                  <Row>
                    <Col xs={12} md={12}>
                      <br></br>
                      <Card
                        size="sm"
                        variant="plain"
                        orientation="horizontal"
                        sx={{ textAlign: 'center', overflow: 'auto', gap: 1, maxWidth: '100%', minWidth: '100%' }}>
                        <CardOverflow variant="solid" color="primary" sx={{ minWidth: '25%', flex: '0 0 200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 'var(--Card-padding)' }}>
                          <Form.Group controlId={`trainingPV-${user.tp_id}`}>
                            <Form.Label>Training Provider</Form.Label>
                            <Form.Select name="trainingProvider" value={(user.tp_id)} isInvalid={!!trainingErrorText[`cnum-${index}`]}
                              onChange={(e) => handleOnChangeTPArray(e, user, "providerName", index)}>
                              <option value="-1">--Choose Training Provider--</option>
                              <option value={(user.tp_id)}>
                                {
                                  trainingProviders.find(
                                    (item) => String(item.tp_id) === String(user.tp_id)
                                  )?.provider_name
                                }
                              </option>
                              {trainingProviders.map((e, key) => {
                                return newUserTrainingData.find(
                                  (item) => item.tp_id == e.tp_id
                                ) == undefined ? (
                                  <option value={e.tp_id} key={key}>
                                    {e.provider_name}
                                  </option>
                                ) : (
                                  ""
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </CardOverflow>

                        <CardOverflow variant="soft" sx={{
                          minWidth: '60%', display: 'flex', flexDirection: 'row',
                          justifyContent: 'space-around', borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'divider'
                        }}>
                          <Form.Group controlId={`formNumC-${index}`}>
                            <Form.Label>
                              Student Number&nbsp;
                              <Tooltip
                                variant="contained"
                                title={trainingProviders.find(
                                  (item) => String(item.tp_id) === String(user.tp_id)
                                )?.student_number_regex_detail}
                                disableRipple
                              >
                                <Icon color="primary" style={{ fontSize: "15px" }}>
                                  <RxInfoCircled style={{ marginBottom: "9px" }} />
                                </Icon>
                              </Tooltip>
                            </Form.Label>
                            <Form.Control
                              //id= {`formNumC1-${user.tp_id}`}
                              type="input"
                              value={user.num}
                              key={user.tp_id || index}
                              isInvalid={!!trainingErrorText[`cnum-${index}`]}
                              disabled={(trainingProviders.find(
                                (item) => String(item.tp_id) === String(user.tp_id)
                              )?.student_number_check == true) ? false : true}
                              // defaultValue={user.num}
                              // value={user.num}
                              placeholder="Enter your student Number"
                              onChange={(e) => handleOnChangeTPArray(e, user, "cnum", index)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <Typography level="body-xs" style={{ marginTop: "3px" }} color="danger">{trainingErrorText[`cnum-${index}`]}</Typography>
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Divider orientation="vertical" >OR</Divider>

                          {(trainingProviders.find(
                            (item) => String(item.tp_id) === String(user.tp_id)
                          )?.student_number_check == true) ?
                            <Box sx={{ orientation: 'horizontal', width: '25%' }}>
                              <Form.Group as={Col} style={{ marginTop: "0.5pc" }} controlId={`formCheck-${index}`}>
                                <FormControlLabel
                                  label={
                                    <Typography level="body-sm">
                                      Request a new student number
                                    </Typography>
                                  }
                                  control={
                                    <Checkbox
                                      name={`trainingChecked1-${index}`}
                                      checked={user.enrollCheck ? true : false}
                                      onChange={(e) => handleOnChangeTPArray(e, user, "enrollCheck", index)}
                                      key={user.tp_id || index}
                                      inputProps={{ "aria-label": "controlled" }}
                                    />
                                  }
                                />
                              </Form.Group>
                            </Box>
                            :
                            <Box sx={{ orientation: 'horizontal', width: '25%' }}>
                            <Form.Group as={Col} style={{ marginTop: "0.5pc" }} controlId={`formCheck-${index}`}>
                              <FormControlLabel
                                label={
                                  <Typography level="body-sm">
                                    Request a new student number
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    name={`trainingChecked1-${index}`}
                                    checked={(trainingProviders.find(
                                      (item) => String(item.tp_id) === (String(user.tp_id))
                                    )?.student_number_check == true) ? false : true}
                                    disabled={true}
                                    key={user.tp_id || index}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Form.Group>
                          </Box>
                          }
                        </CardOverflow>
                        <CardOverflow variant="soft" sx={{ minWidth: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                          <CardContent>
                            <Typography level="body-sm">Consent</Typography>
                            {/* {console.log((user.enrollCheck) || (!user.enrollCheck && user.num))}
                            {(user.enrollCheck) || (!user.enrollCheck && user.num) } */}
                            <Form.Group controlId="formGridState">
                              {(user.consent == false) ?
                                <Tooltip sx={{ width: '20%' }} variant="contained" title="Required">
                                  <IconButton variant="plain" name="consentCheck" color="danger" onClick={(e) => handleOnChangeTPArray(e, user, "consent", index)} aria-label="directions-down">
                                    <ErrorOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                                :
                                <Tooltip sx={{ width: '20%' }} variant="contained" title="Agreed">
                                  {/* <IconButton variant="plain" name="consentCheck" color="success" onClick={(e) => handleOnChangeTPArray(e, user, "consent", index)} aria-label="directions-down"> */}
                                  <IconButton variant="plain" name="consentCheck" color="success" aria-label="directions-down">

                                    <TaskAltIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </Form.Group>
                          </CardContent>
                        </CardOverflow>

                        <CardOverflow variant="soft" sx={{ width: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                          <CardContent style={{ alignContent: "center" }}>
                            <Tooltip variant="contained" title="Remove">
                              <IconButton variant="plain" color="danger" onClick={(e) => handleAddNewField(e, user, "remove")} aria-label="directions-down">
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="horizontal" />
                            {(index == newUserTrainingData.length - 1 &&
                              !showForm &&
                              newUserTrainingData.length < trainingProviders.length) &&
                              <Tooltip variant="contained" title="Add ">
                                <IconButton variant="plain" color="primary" onClick={(e) => handleAddNewField(e, user, "add Another")} aria-label="directions-down">
                                  <ControlPointIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </CardContent>
                        </CardOverflow>
                      </Card>
                    </Col>
                  </Row>
                </>

                );
              })}







              {/* 
                *
                *
                * 
                * 
                * 
                * 
                When Show form is true 
                *
                *
                *
                */}

              {(showForm) &&
                <>
                  <Row>
                    <Col xs={12} md={12}>
                      <br></br>
                      <Card
                        size="sm"
                        variant="plain"
                        orientation="horizontal"
                        sx={{ textAlign: 'center', overflow: 'auto', gap: 1, maxWidth: '100%', minWidth: '100%' }}>
                        <CardOverflow variant="solid" color="primary" sx={{ minWidth: '25%', maxWidth: "25%", flex: '0 0 200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 'var(--Card-padding)' }}>
                          <FormGroup>
                            <Form.Label>Training Provider</Form.Label>
                            <Form.Select
                              name="trainingProvider"
                              value={studentData.providerName || "-1"} // Ensure it resets to default if no value is selected
                              onChange={(e) => handleOnChangeTP(e, "providerName")}
                            >
                              <option value="-1">--Choose Training Provider--</option>
                              {trainingProviders.map((e, key) => {
                                return newUserTrainingData.find((item) => item.tp_id == e.tp_id) === undefined ? (
                                  <option value={e.tp_id} key={key}>
                                    {e.provider_name}
                                  </option>
                                ) : (
                                  ""
                                );
                              })}
                            </Form.Select>

                          </FormGroup>
                        </CardOverflow>

                        <CardOverflow variant="soft" sx={{
                          minWidth: "60%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around',
                          borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'divider'
                        }}>
                          {(trainingProviders.find(
                            (item) => String(item.tp_id) === studentData.providerName
                          )?.student_number_check == true) ? (
                            <Form.Group controlId="formStudentNum">
                              <Form.Label>
                                Student Number&nbsp;
                                <Tooltip
                                  variant="contained"
                                  title={trainingProviders.find(
                                    (item) => String(item.tp_id) === String(studentData.providerName)
                                  )?.student_number_regex_detail}
                                  disableRipple
                                >
                                  <Icon color="primary" style={{ fontSize: "15px" }}>
                                    <RxInfoCircled style={{ marginBottom: "9px" }} />
                                  </Icon>
                                </Tooltip>
                              </Form.Label>
                              <Form.Control
                                type="input"
                                name="cnums"
                                disabled={
                                  studentData.providerName == undefined ||
                                    studentData.providerName == "-1"
                                    ? true
                                    : false
                                }
                                value={studentData.studentNo}
                                onChange={(e) => handleOnChangeTP(e, "studentNo")}
                                isInvalid={!!errorText["cnum"]}
                                placeholder="Enter your student Number"
                              />
                              <Typography level="body-xs" style={{ marginTop: "3px" }} color="danger">{errorText.cnum}</Typography>
                            </Form.Group>

                          ) : (

                            <Form.Group >
                              <Form.Label>
                                Student Number&nbsp;
                              </Form.Label>
                              <Form.Control
                                type="input"
                                name="c_num"
                                disabled={true}
                                placeholder=""
                                value={studentData.studentNo}
                              />
                            </Form.Group>
                          )}
                          <Divider orientation="vertical" >OR</Divider>
                          {(trainingProviders.find(
                            (item) => String(item.tp_id) === studentData.providerName
                          )?.student_number_check == true) ? (
                            <Box sx={{ orientation: 'horizontal', width: '25%' }}>
                              <Typography level="body-sm">
                                <Checkbox
                                  name="trainingChecked2"
                                  checked={studentData.enrollCheck}
                                  onChange={(e) => handleOnChangeTP(e, "enrollCheck")}
                                  // disabled={(trainingProviders.find(
                                  //   (item) => String(item.tp_id) === studentData.providerName
                                  // )?.student_number_check == true) ? false : true}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                                Request a new student number.
                              </Typography>

                            </Box>
                          ) : <Box sx={{ orientation: 'horizontal', width: '25%' }}>
                          <Typography level="body-sm">
                            <Checkbox
                              name="trainingChecked2"
                              checked={studentData.providerName?((trainingProviders.find(
                                (item) => String(item.tp_id) === studentData.providerName
                              )?.student_number_check == true) ? false : true):false}
                              onChange={(e) => handleOnChangeTP(e, "enrollCheck")}
                              disabled={true}
                          
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            Request a new student number.
                          </Typography>

                        </Box>}
                        </CardOverflow>


                        <CardOverflow variant="soft" sx={{ minWidth: '15%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <CardContent>
                            <Typography level="body-sm">Consent</Typography>
                            {(studentData.providerName && studentData.providerName!="-1" )?
                             <FormGroup style={{ alignContent: "center" }}>
                             {(!studentData.consent) ?
                               <Tooltip sx={{ width: '20%' }} variant="contained" title="Required">
                                 <IconButton variant="outlined" name="consentCheck" color="danger" onClick={(e) => handleOnChangeTP(e, "consentCheck")} aria-label="directions-down">
                                   <ErrorOutlineIcon />
                                 </IconButton>
                               </Tooltip>
                               :
                               <Tooltip sx={{ width: '20%' }} variant="contained" title="Agreed">
                                 {/* <IconButton variant="plain" name="consentCheck" color="success" onClick={(e) => handleOnChangeTP(e, "consentCheck")} aria-label="directions-down"> */}
                                 <IconButton variant="plain" name="consentCheck" color="success" aria-label="directions-down">

                                   <TaskAltIcon />
                                 </IconButton>
                               </Tooltip>
                             }
                           <Tooltip variant="contained" title="Remove">
                             <IconButton variant="plain" color="danger" onClick={(e) => handleOnChangeTP(e, "remove")} aria-label="directions-down">
                               <DeleteIcon />
                             </IconButton>
                           </Tooltip>
                           <Divider orientation="horizontal" />
                           </FormGroup>:''}
                           
                            </CardContent>
                            </CardOverflow>

                      </Card>
                    </Col>
                  </Row>
                </>
              }




              {/* <Alert
                show={showValidateCnumError}
                variant="danger"
                className="w-100 mt-3 ml-3 "
                onClose={() => setValidateCnumError(false)}
                dismissible
              >
                Regex not Valid
              </Alert> */}
              
              <Row>
              {(showValidateCnumError) &&
                <Alert
                  variant="outlined"
                  color="danger"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="neutral">
                      <CloseIcon onClick={() => setValidateCnumError(false)} />
                    </IconButton>
                  }
                >
                  Regex not Valid
                </Alert>
              }
              {/* <Alert
                show={showValidRegex}
                variant="success"
                className="w-100 mt-3 ml-3 "
                onClose={() => setShowValidRegex(false)}
                dismissible
              >
                Regex Validated
              </Alert> */}

              {(showValidRegex) &&
                <Alert
                  variant="outlined"
                  color="success"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="neutral">
                      <CloseIcon onClick={() => setShowValidRegex(false)} />
                    </IconButton>
                  }
                >
                  Regex Validated
                </Alert>
              }


              {/* {showCnumError ? (
                <p style={{ color: "red" }}>{showCnumError}</p>
              ) : (
                ""
              )} */}

              {(showCnumError) &&
                <Alert color="danger" variant="soft"
                style={{marginTop:"2pc"}}
                startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="danger">
                      <CloseIcon onClick={() => setShowCnumError("")} />
                    </IconButton>
                  }
                  className='text-left'
                >{showCnumError}
                </Alert>}

              {(validationError.number && errorText["cnum"] !== "") && <Alert color="danger" style={{marginTop:"2pc"}} className='text-left'>{errorText["cnum"]}</Alert>}

              </Row>

            
            {/* <br></br>
                        <Divider textAlign="left" role="presentation"><Typography variant='caption' gutterBottom>Additional Details</Typography></Divider>
                        <br></br>
                        <Row>
                            <Form.Group as={Col} controlId="formGridCareerGoal">
                                <Form.Label>Career Goal</Form.Label>
                                <Form.Control type="input" value={newUser.careerGoal} onChange={handleOnChange} name="careerGoal" placeholder="Enter your career goal" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridComCode">
                                <Form.Label>ComCode</Form.Label>
                                <Form.Control type="input" value={newUser.comCode} onChange={handleOnChange} name="comCode" placeholder="Enter your COM CODE" />
                            </Form.Group>
                        </Row> */}
            <br></br>

            {/* <Row>
              <Form.Group as={Col} controlId="formEnroll">
                <FormControlLabel
                  label={
                    <Typography variant="subtitle1">
                      Create a new Lambton College student account using the
                      information entered above.
                      <Tooltip
                        variant="contained"
                        title="A Lambton College student account is required for registering in Lambton College microcredentials and for the the Carepassport portal to be automatically updated when you pass your training.  If you are unsure of whether you have a Lambton College account, submit to create a new account and you will be notified of the details of your account, if it is already in the system."
                        disableRipple
                      >
                        <Icon color="primary" style={{ fontSize: "15px" }}>
                          <RxInfoCircled style={{ marginBottom: "9px" }} />
                        </Icon>
                      </Tooltip>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      name="enrollCheck"
                      checked={checked}
                      onChange={handleOnChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </Form.Group>
            </Row> */}
            <br></br>
            <Button
              variant="primary"
              type="submit"
              disabled={Object.values(passwordError).includes(false)}
            >
              Submit
            </Button>
          </Form>
        </Row >
        <br></br>
        <Row>
          <Col>
            Already have an account? <a href="/login">Login Now</a>
          </Col>
        </Row>
        <br></br>
      </Container >
      <SuccessPopUp
        show={showUserMsg}
        data={userMsg}
        onHide={() => {
          setShowUserMsg(false);
        }}
      />
      <ErrorPopUp
        show={showErrorMsg}
        data={errorMsg}
        onHide={() => {
          setShowErrorMsg(false);
        }}
      />
      <SpinnerBox
        show={showSpinner}
        onHide={() => {
          setShowSpinner(false);
        }}
      />
      <UserConsentDialog
        show={consentDialog}
        handleUpdateCallback={handleUpdateCallback}
        onHide={() => setConsentDialog(false)}
      />
    </div >
  );
}

export default UserRegistration;